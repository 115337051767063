import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/Header'
import Lineup from 'gatsby-theme-psg/src/components/Lineup'
import Lead from '../components/Lead';

export const LineupPageTemplate = ({ title, content }) => {
    return (
        <React.Fragment>
            <Header>
                <Lead title={title} content={content}/>
            </Header>
            <div className={"content"}>
                <div className="container my-5 py-5">
                    <Lineup />
                </div>
            </div>
        </React.Fragment>
    )
}

const LineupPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
            <LineupPageTemplate title={post.frontmatter.title} content={post.html} />
        </Layout>
    )
}

export default LineupPage

export const pageQuery = graphql`
    query LineupPage {
        markdownRemark(frontmatter: { templateKey: { eq: "lineup-page" } }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`
